<template>
    <svg width="31" height="30" viewBox="0 0 31 30" :fill="color" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00258 29.95C6.58258 29.95 6.17258 29.82 5.83258 29.57C5.23258 29.12 4.92258 28.38 5.00258 27.64L5.73258 19.45L0.502582 13.29C0.0225823 12.72 -0.127418 11.94 0.112582 11.23C0.332582 10.54 0.902582 10.02 1.60258 9.85004L9.22258 8.05004L13.2226 1.05004C13.7726 0.06004 15.0226 -0.28996 16.0126 0.26004C16.3426 0.45004 16.6226 0.72004 16.8026 1.05004L20.8026 8.10004L28.4226 9.90004C29.1226 10.07 29.6926 10.59 29.9126 11.28C30.1526 11.99 30.0026 12.77 29.5226 13.34L24.3226 19.45L25.0526 27.64C25.1226 28.39 24.7926 29.13 24.1726 29.57C23.5926 29.99 22.8226 30.07 22.1726 29.77L15.0026 26.53L7.84258 29.77C7.58258 29.89 7.29258 29.95 7.00258 29.95ZM15.0526 1.95004L10.7526 9.38004C10.6126 9.62004 10.3826 9.80004 10.1126 9.86004L2.11258 11.75C2.05258 11.85 2.04258 11.91 2.11258 11.96L7.56258 18.45C7.73258 18.65 7.81258 18.92 7.79258 19.18L7.02258 27.78C6.93258 27.95 7.00258 27.95 7.00258 27.95L14.5826 24.53C14.8426 24.41 15.1426 24.41 15.4026 24.53L23.0026 27.95C23.0026 27.95 23.0926 27.95 23.0826 27.82L22.3126 19.22C22.2926 18.96 22.3726 18.69 22.5426 18.49L28.0026 12C28.0226 11.96 28.0226 11.9 28.0026 11.86L19.9026 9.86004C19.6326 9.80004 19.4026 9.62004 19.2626 9.38004L15.0526 1.95004Z" :fill="simInfo.color"/>
    </svg>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
props:{
    border_color:{
        type: String,
        default: '#ACABAB'
    },
    color:{
        type: String,
        default: 'none'
    }
},
computed:{
    ...mapGetters({
        simInfo: 'simulator/getSimInfo'
    })
},

name: "Star"
}
</script>

<style scoped>

</style>