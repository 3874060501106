<template>
    <svg width="23" height="30" viewBox="0 0 23 30" :fill="color" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1739 29.3478C22.1739 29.7065 21.8804 30 21.5217 30H3.26087C1.46087 30 0 28.5391 0 26.7391V3.26087C0 1.46087 1.46087 0 3.26087 0H20.2174C20.4848 0 20.7196 0.163043 20.8174 0.397826C20.85 0.476087 20.8696 0.56087 20.8696 0.652174V5.21739H21.5217C21.7043 5.21739 21.8674 5.28913 21.9848 5.41304C22.1022 5.53044 22.1739 5.69348 22.1739 5.86957V29.3478ZM1.30435 26.7391C1.30435 27.8217 2.17826 28.6957 3.26087 28.6957H5.21739V6.52174H3.26087C2.52391 6.52174 1.85217 6.26739 1.30435 5.85652V26.7391ZM19.5652 5.21739V1.30435H3.26087C2.17826 1.30435 1.30435 2.17826 1.30435 3.26087C1.30435 4.34348 2.17826 5.21739 3.26087 5.21739H19.5652ZM20.8696 6.52174H6.52174V28.6957H20.8696V6.52174ZM3.26087 3.91304C2.90217 3.91304 2.6087 3.61957 2.6087 3.26087C2.6087 2.90217 2.90217 2.6087 3.26087 2.6087H17.6087C17.9674 2.6087 18.2609 2.90217 18.2609 3.26087C18.2609 3.61957 17.9674 3.91304 17.6087 3.91304H3.26087Z" :fill="simInfo.color"/>
    </svg>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props:{
        border_color:{
            type: String,
            default: '#ACABAB'
        },
        color:{
            type: String,
            default: 'none'
        }
    },
    computed:{
        ...mapGetters({
            simInfo: 'simulator/getSimInfo'
        })
    },
    name: "Theory"
}
</script>

<style scoped>

</style>