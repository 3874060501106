import { render, staticRenderFns } from "./CourseItems.vue?vue&type=template&id=05e3fc21&scoped=true&"
import script from "./CourseItems.vue?vue&type=script&lang=js&"
export * from "./CourseItems.vue?vue&type=script&lang=js&"
import style0 from "./CourseItems.vue?vue&type=style&index=0&id=05e3fc21&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e3fc21",
  null
  
)

export default component.exports