import axios from 'axios';
const headers = {
    // 'X-App-Name': 'tamoschool.mysimulator.ru'
    'X-App-Name': window.location.hostname
}
if (localStorage.getItem("token")){
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
}

export default
    axios.create({
        baseURL: 'https://api.mysimulator.ru/',


        // headers: {'X-App-Name': window.location.hostname}
        headers: headers
        
    });
    // headers: {'X-App-Name': window.location.hostname}
    