import api from '@/api/';

export default {
    namespaced: true,
    state () {
        return {
            welcomeMessage: {},
        }
    },
    getters: {
        getWelcomeMessage: stateFreezed => stateFreezed.welcomeMessage,

    },
    actions: {
        async GET_WELCOME_MESSAGE ({ commit }) {
            const response = await api.get('/api/welcome_message/');
            commit('SET_WELCOME_MESSAGE', response.data)
        }
    },
    mutations: {
        SET_WELCOME_MESSAGE (state, response) {
            state.welcomeMessage = response
        }
    }
}