<template>
    <div class="pages-preview" v-if="pageLessons && pageInfo && !pageInfo.is_onboarding">
        <!-- <div class="pages-preview__lesson-title">
            {{chapter.name}}
        </div> -->
        <div class="lesson mb-4" v-for="lesson in simInfo.lesson_set" :key="'lesson'+lesson.id">
            <div class="lesson__title" v-if='pageInfo.lesson === lesson.id' style="font-weight:bold">
                 {{lesson.name}}
            </div>
            <div class="lesson__title " v-else-if='lesson.active && (lesson.order_active || !simInfo.order_lesson) && (simInfo.paid||simInfo.price==0||lesson.price==0)' @click="selectPage(lesson.current_page)">
                {{lesson.name}}
            </div>
            <div class="lesson__title inactive" v-else>
               {{lesson.name}}
            </div>
            <div class="pages_list mt-3" v-if="pageInfo.lesson === lesson.id">
                <div class="pages-preview__page mb-3 mt-1 pl-4" v-for="page in pageLessons" :key="'page'+page.id" >
                    <div class="pages-preview__page__title" v-if='pageInfo.id === page.id' style="font-weight:bold">
                        {{`${page.name}`}}
                    </div>
                    <div class="pages-preview__page__title inactive" v-else-if="!page.active && page.sequence_no>pageInfo.sequence_no && page.sequence_no != 1 && !pageLessons[page.sequence_no-2].complete" >
                        {{`${page.name}`}}
                    </div>
                    <div class="pages-preview__page__title" v-else-if='page.complete' @click="selectPage(page.id)">
                        {{`${page.name}`}}
                    </div>
                    <div class="pages-preview__page__title" v-else @click="selectPage(page.id)">
                        {{`${page.name}`}}
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
export default {
    name: 'PagePreview',
    computed:{
        ...mapGetters({
            pageInfo: 'page/getPageInfo',
            chapter: 'chapter/getCurrChapter',
            pageLessons: 'page/getPageLessons',
            simInfo: "simulator/getSimInfo"
        }), 
        data(){
            return{
                current: 0, 
                lesson:0, 
                clear: true
            }
        },

    },
    mounted() {
        if(this.pageInfo && this.pageInfo.lesson)
        {

            this.$store.dispatch('page/GET_LESSONS', this.pageInfo.lesson);
            
        }
        
    },
    watch: {
        pageInfo(val) {
            if(val && val.lesson != 0)
            {
                this.getpages(val.lesson)
                
            }
        }
    },
    methods:{
        async selectPage(id){
            this.$store.commit('page/CLEAR_PAGE_PLACE_SET');
            await this.$store.dispatch('page/FETCH_PAGE', id);
            await this.$store.dispatch('page/SET_PAGE', {
                vm: this,
                page: id
            });
        }, 
        async getpages(id)
        {

            if(this.pageInfo.id != this.lesson && !this.pageInfo.is_onboarding)
            {
                
                await this.$store.dispatch('page/GET_LESSONS', id);
                this.lesson = this.pageInfo.id
            }
            
            
            
        }
    }
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';

.pages_list
{
    border-left: 3px $primary_color solid;
}
.pages-preview{
    
    &__page{
        font-size: 1rem;
        // margin-bottom: 20px;
        &__title{
            cursor: pointer;
            &.inactive{
                color: $second_font_color;
                pointer-events: none;
            }
            // &.active{
            //     color: $primary_color;
            // }
            
        }
    }
}
.pages-preview__page:last-child{
    margin-bottom: 0;
}
.lesson{
    
     &__title{
        cursor: pointer;
        &.inactive{
            color: $second_font_color;
            pointer-events: none;
            cursor: not-allowed !important;
        }
        &.active{
            font-weight: bold;
        }
        
    }
}
</style>