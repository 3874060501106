<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8523 0C18.8718 0.0246094 22.3007 1.34531 25.1636 4.04414C28.0183 6.74297 29.5769 10.0898 29.7655 14.0191C29.9624 18.2191 28.6171 21.8941 25.705 24.9375C22.8421 27.9317 19.3066 29.4738 15.1558 29.5149C11.2921 29.5559 7.90421 28.2598 5.06593 25.643C1.71905 22.5504 0.111238 18.6785 0.283504 14.1258C0.422957 10.3113 1.89132 7.03008 4.59015 4.33945C7.43663 1.49297 10.9148 0.0738282 14.8523 0Z" fill="#0087CB"/>
        <path d="M10.4883 16.7508C9.68444 16.5047 8.87233 16.2504 8.06843 16.0043C7.67468 15.8812 7.28093 15.7582 6.88718 15.6351C6.73952 15.5859 6.60007 15.5367 6.47702 15.4465C6.24733 15.2742 6.23093 15.0609 6.4278 14.8476C6.55905 14.7 6.72311 14.6015 6.90358 14.5277C7.37936 14.339 7.85514 14.1504 8.33093 13.9617C11.6122 12.6984 14.8934 11.4269 18.1829 10.1637C19.2247 9.76171 20.2583 9.35976 21.3001 8.9578C21.4395 8.90038 21.5872 8.86757 21.743 8.89218C22.0548 8.9414 22.2926 9.19569 22.3255 9.52382C22.3419 9.70429 22.3337 9.88476 22.3008 10.0652C21.8989 11.9519 21.4969 13.8469 21.095 15.7336C20.7669 17.2922 20.4305 18.8508 20.1024 20.4176C20.0286 20.7703 19.9548 21.123 19.8809 21.4758C19.8481 21.648 19.7989 21.8121 19.7169 21.9679C19.5528 22.2715 19.2903 22.3863 18.954 22.3207C18.7489 22.2797 18.5684 22.1894 18.4044 22.0664C17.2723 21.2297 16.1403 20.3847 15.0001 19.548C14.9508 19.507 14.8934 19.4742 14.8442 19.4414C14.6473 19.384 14.5079 19.2363 14.352 19.1215C13.8106 18.7277 13.2692 18.3176 12.7278 17.9238C12.6868 17.891 12.6458 17.8582 12.613 17.8172C12.5637 17.7351 12.613 17.6859 12.6622 17.6367C13.4005 16.9805 14.1305 16.3078 14.8688 15.6515C15.402 15.1676 15.9434 14.6836 16.4766 14.1996C17.0098 13.7156 17.543 13.2398 18.0762 12.764C18.4946 12.3867 18.913 12.0176 19.3231 11.6402C19.3559 11.6074 19.3887 11.5746 19.4215 11.5418C19.4462 11.509 19.479 11.4762 19.4626 11.4351C19.4462 11.3859 19.3969 11.3859 19.3559 11.3859C19.2083 11.3695 19.0852 11.4269 18.9622 11.5008C18.511 11.7715 18.0762 12.0586 17.6333 12.3375C16.288 13.1824 14.9344 14.0355 13.5891 14.8805C12.6622 15.4629 11.7352 16.0453 10.8083 16.6359C10.7016 16.6933 10.6278 16.8 10.4883 16.7508Z" fill="#FCFDFE"/>
    </svg>


</template>

<script>
    export default {
        name: "Telegram"
    }
</script>

<style scoped>

</style>