var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid px-0",style:(_vm.cssVars),attrs:{"id":"app"}},[_c('loading',{attrs:{"active":_vm.isLoadingApp || _vm.isLoading,"is-full-page":true}}),(_vm.mainColor)?[(_vm.isLogin)?_c('div',{staticClass:"app-container row mr-0",class:{ blur: !_vm.isLogin && _vm.showLogin }},[(!_vm.isMobile)?_c('div',{staticClass:"col-xl-3 col-4"},[_c('div',{staticClass:"position-fixed",class:{ tablet_width: _vm.isTablet }},[_c('div',{staticClass:"scroll-position"},[_c('SideBar')],1)])]):(_vm.routeName === 'Courses')?_c('div',{staticClass:"col-12 px-0"},[_c('MobileHeader')],1):_vm._e(),_c('div',{staticClass:"col-12 col-md-8 col-xl-9 px-md-0 pr-1",staticStyle:{"position":"unset"}},[_c('router-view')],1)]):_vm._e(),(!_vm.isLogin && _vm.showLogin)?_c('div',{staticClass:"col-md-9"},[_c('router-view')],1):_vm._e(),(
      _vm.simInfo.simulator_script &&
      _vm.routeName != 'Login' &&
      _vm.routeName != 'Register' &&
      _vm.routeName != 'Recover' &&
      _vm.routeName != 'MakeUser' &&
      _vm.routeName != 'Profile'
    )?_c('my-scripts'):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }